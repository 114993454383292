import { createSlice } from "@reduxjs/toolkit";
interface IInitialState {
  afterOrder: boolean;
}
const initialState: IInitialState = {
  afterOrder: false
};
export const afterOrderSlice = createSlice({
  name: "afterOrderSlice",
  initialState,
  reducers: {
    setAnswerAfterOrder: (state, action) => {
      state.afterOrder = action.payload;
    }
  }
});
export const {
  setAnswerAfterOrder
} = afterOrderSlice.actions;
export default afterOrderSlice.reducer;