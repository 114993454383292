"use client";
import { useCallback, useEffect } from "react";
import { useDebounce } from "@/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { getCart } from "@/shared/redux/features/userSlice";
import { getOrderInfo } from "@/shared/redux/features/orderSlice";
import { isCartDisable, setPriceBlockDisable } from "@/shared/redux/features/areaDisable";
import { useCheckCoupon } from "@/hooks/useCheckCoupon";
import { setCartTooltip } from "@/shared/redux/features/popupsSlice";
import { useGetCity } from "@/hooks/useGetCity";

export const useCheckChangeCart = () => {
  const userCart = useAppSelector(getCart);
  const step = useAppSelector(getOrderInfo).step;
  const cartDisable = useAppSelector(isCartDisable);
  const { city } = useGetCity();
  const { checkCoupon } = useCheckCoupon();
  const dispatch = useAppDispatch();

  const changeUserCart = useCallback(() => {
    if (!cartDisable) {
      dispatch(setPriceBlockDisable(true));
      checkCoupon();
    }
  }, []);

  const debouncedChangeUserCart = useDebounce(changeUserCart, 1000);

  useEffect(() => {
    dispatch(setCartTooltip(true));
  }, [userCart.length]);
  useEffect(() => {
    if (!cartDisable) {
      debouncedChangeUserCart();
    }
  }, [userCart.length, step, cartDisable, city]);
};
