"use client";
import { getCouponInfo } from "@/utils/api/cart";
import { getOrderInfo, setPromocode } from "@/shared/redux/features/orderSlice";
import { addNewPrice, getCart, removePrice } from "@/shared/redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { useCallback, useEffect, useRef } from "react";
import {
  cartActiveProducts,
  isCartDisable,
  setCartDisable,
  setPriceBlockDisable,
} from "@/shared/redux/features/areaDisable";
import { callMessage } from "@/utils/functions/callMessage";
import { ICardMini } from "@/shared/types/types";
import { checkIsAvailable } from "@/utils/functions/check-is-available";
import { useGetCity } from "@/hooks/useGetCity";

export const useCheckCoupon = () => {
  const coupon = useAppSelector(getOrderInfo).coupon;
  const userCart = useAppSelector(getCart);
  const dispatch = useAppDispatch();
  const cartDisable = useAppSelector(isCartDisable);
  const activeProducts = useAppSelector(cartActiveProducts);
  const { city } = useGetCity();
  const userCartRef = useRef<any[]>(userCart);
  const activeProductsRef = useRef<any[]>(userCart);

  const smashProducts = useCallback(() => {
    const availableProducts: ICardMini[] = [];
    const unavailableProducts: ICardMini[] = [];

    userCartRef.current.forEach((product) => {
      const itemInfo = activeProductsRef.current.find((cartItem) => cartItem.id === product.product_id);
      const newProduct = { ...itemInfo };
      if (newProduct && product.old && product.new) {
        newProduct.old = product.old;
        newProduct.new = product.new;
      }
      const availableCount = checkIsAvailable(itemInfo as any, city.name);
      if (!itemInfo) return;
      if (availableCount > 0) {
        availableProducts.push({ ...itemInfo, ...product });
      } else {
        unavailableProducts.push({ ...itemInfo, ...product });
      }
    });

    return { availableProducts, unavailableProducts };
  }, [
    activeProductsRef,
    activeProductsRef.current,
    userCartRef.current,
    userCartRef.current.length,
    cartDisable,
    city,
    cartDisable,
  ]);

  const { availableProducts, unavailableProducts } = smashProducts();

  const checkCoupon = useCallback(async () => {
    if (!userCartRef.current || !userCartRef.current.length) {
      dispatch(setPriceBlockDisable(false));
      dispatch(setCartDisable(false));
      return;
    }
    if (coupon) {
      if (!activeProductsRef.current.length) {
        dispatch(setPriceBlockDisable(false));
        dispatch(setCartDisable(false));
        return;
      }
      const data = {
        coupon: coupon,
        items: activeProductsRef.current.filter((item) => !item.isRemoved),
        clear: false,
      };
      try {
        getCouponInfo(data)
          .then((res) => {
            if (res.success) {
              dispatch(
                setPromocode({
                  coupon: coupon,
                  nsum: Number(res.items.nsum.toFixed(0)),
                  sum: Number(res.items.sum.toFixed(0)),
                })
              );
              if (res.items.items) {
                res.items.items.map((item: any) => {
                  dispatch(addNewPrice(item));
                });
                dispatch(setCartDisable(false));
              }
            } else {
              clearPromo();
            }
          })
          .catch((err) => {
            clearPromo();
          });
      } catch (error: any) {
        clearPromo();
        if (error.error_msg) {
          callMessage("Ошибка", error.error_msg, false);
        }
      } finally {
        dispatch(setCartDisable(false));
        dispatch(setPriceBlockDisable(false));
      }
      dispatch(setPriceBlockDisable(false));
      dispatch(setCartDisable(false));
    } else {
      dispatch(setPriceBlockDisable(false));
      dispatch(setCartDisable(false));
    }
  }, [
    userCartRef.current,
    userCartRef.current.length,
    cartDisable,
    availableProducts,
    activeProductsRef.current,
    activeProductsRef.current.length,
  ]);

  const clearPromo = () => {
    const data = {
      coupon: coupon,
      items: userCart.filter((item) => !item.isRemoved),
      clear: true,
    };
    getCouponInfo(data).then((res) => {
      dispatch(
        setPromocode({
          coupon: "",
          nsum: 0,
          sum: 0,
        })
      );
    });
    userCart.map((item: any) => {
      dispatch(removePrice(item));
    });
    return new Promise((resolve) => resolve(true));
  };

  useEffect(() => {
    userCartRef.current = userCart;
  }, [userCart]);

  useEffect(() => {
    activeProductsRef.current = activeProducts;
  }, [activeProducts]);

  return { checkCoupon, clearPromo, availableProducts, unavailableProducts };
};
