export const scrollToTop = () => {
  const ACCELERATION = 1.09;
  const DECELERATION = 0.91;

  const processScroll = (scrollDistance: number) => {
    let currentHeight = window.scrollY || document.body.scrollTop;
    if (scrollDistance <= 0) {
      processScrollUp(Math.abs(scrollDistance), currentHeight, 100, 100);
    }
  };
  function processScrollUp(distanceLeft: number, currentHeight: number, distanceCovered: number, stepSize: number) {
    setTimeout(() => {
      if (distanceLeft > distanceCovered) {
        stepSize = ACCELERATION * stepSize;
      } else {
        stepSize = Math.ceil(DECELERATION * stepSize);
      }
      window.scrollTo(0, currentHeight);
      if (distanceLeft > 0) {
        processScrollUp(distanceLeft - stepSize, currentHeight - stepSize, distanceCovered + stepSize, stepSize);
      }
    }, 10);
  }

  const targetElement = document.getElementById("main");
  if (targetElement) {
    return processScroll(targetElement.getBoundingClientRect().y);
  }
};
