"use client";

import styles from "./index.module.scss";
import Link from "next/link";
import { useEffect, useState } from "react";
import { setCookie } from "@/utils/functions/cookie";
import { keyGenerator } from "@/utils/functions/keyGenerator";
import { Icon } from "@/shared/components/Icon";
import { useDisableScroll } from "@/hooks/useDisableScroll";
const CookiePopup = () => {
  const [isClosed, setIsClosed] = useState<boolean>(true);
  const {
    disableScroll,
    enableScroll
  } = useDisableScroll();
  const checkCookie = () => {
    if (typeof window === "undefined") return null;
    const value = `; ${document.cookie}`;
    const parts = value.split(`; acceptCookieOhotaktiv=`);
    if (parts.length === 2) {
      return parts[1].split(";").shift();
    }
    return null;
  };
  const isAccepted = checkCookie();
  useEffect(() => {
    if (!isAccepted) {
      // document.body.style.overflow = "hidden";
      disableScroll();
      setIsClosed(false);
    } else {
      enableScroll();
      // document.body.style.overflow = "auto";
    }
  }, [isAccepted]);
  const cookie = () => {
    setCookie("acceptCookieOhotaktiv", keyGenerator(), {
      expires: 60 * 60 * 24
    });
    const check = checkCookie();
    if (check) setIsClosed(true);
  };
  if (isClosed) return null;
  return <div className={styles.shadow} data-sentry-component="CookiePopup" data-sentry-source-file="index.tsx">
      <div className={styles.alert}>
        <div className={styles.alert_container}>
          <button className={styles.close} onClick={cookie}>
            <Icon name={"Close"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </button>
          <div className={styles.alert_container_text}>
            <p className={styles.title}>Внимание!</p>
            <span>
              Уважаемые пользователи сайта! Данный сайт предназначен для лиц старше 18 лет. Если вам меньше 18 лет,
              незамедлительно покиньте его. Для улучшения работы сайта и его взаимодействия с пользователями мы
              используем файлы cookie. Продолжая работу с сайтом, вы разрешаете
              <Link href={"/policy/"} className={styles.link} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                использование cookie-файлов.
              </Link>
              В соответствии c ФЗ {"Об оружии"} от 13.12.1996 N 150-ФЗ отдельные товары продаются только в розничных
              магазинах при предъявлении лицензии и паспорта РФ
            </span>
          </div>
          <button onClick={cookie} className={styles.accept}>
            Понятно
          </button>
        </div>
      </div>
    </div>;
};
export default CookiePopup;