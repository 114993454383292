"use client";

import { scrollToTop } from "@/utils/functions/scrollToTop";
import styles from "./index.module.scss";
import { FC, useEffect, useState } from "react";
import { Icon } from "@/shared/components/Icon";
const ArrowTop: FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  if (!isVisible) return null;
  return <button className={styles.btn} onClick={scrollToTop} data-sentry-component="ArrowTop" data-sentry-source-file="index.tsx">
      <Icon name={"Arrow"} fill={"#FFFFFF"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
    </button>;
};
export default ArrowTop;