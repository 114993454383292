export const useDisableScroll = () => {
  const disableScroll = () => {
    document.body.classList.add("without-scroll");
  };

  const enableScroll = () => {
    document.body.classList.remove("without-scroll");
  };

  return { disableScroll, enableScroll };
};
