import { IItem } from "@/shared/types/types";
export const checkIsAvailable = (item: IItem, city: string) => {
  if (item) {
    if (item.not_delivery) {
      if (item.not_delivery.includes(city)) {
        return 0;
      }
    }
    const diffPack = item.diff_pack ? Number(item.diff_pack.value) : 1;
    if (!item.available) {
      return 0;
    } else {
      if (item.delivery === false) {
        const availableCities = item.store ? item.store.filter((store: any) => store.name.includes(city)) : [];
        const availableCount = availableCities ? availableCities.reduce((accumulator: any, currentValue: any) => Number(accumulator) + currentValue.amount, 0) : 0;
        if (availableCount > 0 && availableCount >= diffPack) {
          return availableCount;
        } else {
          return 0;
        }
      } else {
        const availableCities = item.store ? item.is_bullet ? item.store.filter((store: any) => store.name.includes(city)) : item.store : [];
        const availableCount = availableCities ? availableCities.reduce((accumulator: any, currentValue: any) => Number(accumulator) + currentValue.amount, 0) + Number(item.quantity) : Number(item.quantity);
        if (availableCount > 0 && availableCount >= diffPack) {
          return availableCount;
        } else {
          return 0;
        }
      }
    }
  } else {
    return 0;
  }
};