"use client";
import { isAuthOpenState, setAuthIsOpen } from "@/shared/redux/features/popupsSlice";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";

export const useAuthModal = () => {
  const dispatch = useAppDispatch();
  const showAuthModal = useAppSelector(isAuthOpenState)?.isOpen;
  const handleOpenAuthModal = () => {
    dispatch(setAuthIsOpen(true));
  };
  const handleCloseAuthModal = () => dispatch(setAuthIsOpen(false));
  return { showAuthModal, handleOpenAuthModal, handleCloseAuthModal } as const;
};
