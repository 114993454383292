import { request } from "../functions/request";
export async function getCartList(ids?: string) {
  if (!ids) {
    return {
      items: {
        items: []
      }
    };
  }
  if (!ids.length) {
    return {
      items: {
        items: []
      }
    };
  }
  const url = `/catalog/get_items_list.php?${ids.split(",").map(id => `id[]=${id}`).join("&")}&for_cart=true&price_check=true&stock=not_now`;
  return request(url, {
    method: "POST"
  });
}
export async function getCouponInfo(data: any) {
  return request(`/order/checker/promogod.php`, {
    method: "POST",
    body: JSON.stringify(data),
    cache: "no-store"
  });
}