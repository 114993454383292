import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createMigrate, FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "./storage";
import city from "@/shared/redux/features/citySlice";
import search from "@/shared/redux/features/searchSlice";
import order from "@/shared/redux/features/orderSlice";
import viewed from "@/shared/redux/features/viewedSlice";
import user, { initialStateUser } from "@/shared/redux/features/userSlice";
import popups from "@/shared/redux/features/popupsSlice";
import stories from "@/shared/redux/features/storiesSlice";
import afterOrderSlice from "@/shared/redux/features/afterOrderSlice";
import areaDisable from "@/shared/redux/features/areaDisable";
export const newVersion = 7.03;
export const migration = {
  [newVersion]: (state: any) => {
    if (!state) return {};
    const newState = {
      ...state
    };
    if (newState.user) {
      newState.user = {
        ...newState.user
      };
      if (newState.user.user) {
        const userInfo = newState.user.user.info || {};
        const userAuth = newState.user.user.auth || {};
        newState.user.user = {
          ...initialStateUser.user,
          info: {
            ...initialStateUser.user.info,
            cart: userInfo.cart || initialStateUser.user.info.cart,
            favourites: userInfo.favourites || initialStateUser.user.info.favourites,
            name: userInfo.name || initialStateUser.user.info.name,
            lastname: userInfo.lastname || initialStateUser.user.info.lastname,
            email: userInfo.email || initialStateUser.user.info.email,
            phone: userInfo.phone || initialStateUser.user.info.phone,
            address: userInfo.address || initialStateUser.user.info.address,
            personal_birthday: userInfo.personal_birthday || initialStateUser.user.info.personal_birthday,
            level: userInfo.level || initialStateUser.user.info.level,
            uf_card_active: userInfo.uf_card_active || initialStateUser.user.info.uf_card_active,
            uf_card_policy: userInfo.uf_card_policy || initialStateUser.user.info.uf_card_policy,
            uf_card_number: userInfo.uf_card_number || initialStateUser.user.info.uf_card_number
          },
          auth: {
            ...initialStateUser.user.auth,
            isAuth: userAuth.isAuth || initialStateUser.user.auth.isAuth,
            connectVk: userAuth.connectVk || initialStateUser.user.auth.connectVk,
            connectYandex: userAuth.connectYandex || initialStateUser.user.auth.connectYandex,
            connectMailru: userAuth.connectMailru || initialStateUser.user.auth.connectMailru
          },
          notifications: initialStateUser.user.notifications
        };
      }
    }
    return newState;
  }
};
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
const persistConfig = {
  key: "root",
  storage,
  version: newVersion,
  whitelist: ["viewed", "city", "order", "search", "user", "stories", "afterOrderSlice"],
  migrate: createMigrate(migration, {
    debug: process.env.NODE_ENV !== "production"
  })
};
const rootReducer = combineReducers({
  viewed,
  city,
  order,
  search,
  user,
  popups,
  stories,
  afterOrderSlice,
  areaDisable
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = (getDefaultMiddleware: any) => getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  }
});
export let store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware
});

// Функция для очистки хранилища при критических ошибках
const handlePersistError = () => {
  try {
    localStorage.removeItem("persist:root");
    console.warn("Redux persist storage was cleared due to an error");
  } catch (e) {
    console.error("Failed to clear persist storage:", e);
  }
};

// @ts-ignore
export const persistor = persistStore(store, null, err => {
  if (err) {
    console.error("Error rehydrating store:", err);
    handlePersistError();
    // Пересоздаем хранилище после очистки
    window.location.reload();
  }
});