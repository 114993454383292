"use client";
import { getCookie, setCookie } from "@/utils/functions/cookie";
import { useEffect } from "react";
import { getHash } from "@/utils/functions/getHash";

export const useABTest = () => {
  const isWindow = typeof window !== "undefined";
  const testGroup = isWindow ? getCookie("ab_variant") : false;
  useEffect(() => {
    let storedVariant = testGroup;
    if (!storedVariant) {
      const hash = getHash(navigator.userAgent);
      storedVariant = hash % 2 === 0 ? "A" : "B";
      setCookie("ab_variant", storedVariant);
    }
  }, [testGroup]);

  return { testGroup };
};
