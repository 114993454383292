"use client";
import { getCookie, setCookie } from "@/utils/functions/cookie";
import { useEffect, useState } from "react";
import { defaultCity } from "@/shared/helpers/data/sharedHelpers";

export const useGetCity = () => {
  const [city, setCity] = useState<typeof defaultCity>(() => {
    const cookie = typeof window !== "undefined" ? getCookie("choosedCity") : false;
    if (cookie) {
      try {
        return JSON.parse(cookie);
      } catch (e) {
        console.error("Error parsing city cookie:", e);
        return defaultCity;
      }
    }
    return defaultCity;
  });

  useEffect(() => {
    const handleCookieChange = () => {
      const cookie = typeof window !== "undefined" ? getCookie("choosedCity") : false;
      if (cookie) {
        try {
          const parsedCity = JSON.parse(cookie);
          if (parsedCity.code !== city.code) {
            setCity(parsedCity);
          }
        } catch (e) {
          console.error("Error parsing city cookie:", e);
        }
      } else {
        const empty = { ...defaultCity, default: false };
        setCookie("choosedCity", JSON.stringify(empty));
      }
    };

    handleCookieChange();

    const intervalId = setInterval(handleCookieChange, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { city };
};
