import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/shared/redux/store";
interface IInitialState {
  city: string;
  code: string;
  firstTime: boolean;
}
export const initialStateCity: IInitialState = {
  city: "Москва",
  code: "moskva",
  firstTime: true
};
export const city = createSlice({
  name: "city",
  initialState: initialStateCity,
  reducers: {
    setCity: (state: any, action: {
      payload: {
        name: string;
        code: string;
      };
    }) => {
      state.city = action.payload.name;
      state.code = action.payload.code;
    },
    setFirstTime: (state: any, action: {
      payload: boolean;
    }) => {
      state.firstTime = action.payload;
    }
  }
});
export const {
  setCity,
  setFirstTime
} = city.actions;
export const getCity = (state: RootState) => state.city;
export const getCityCode = (state: RootState) => state.city.code;
export const getFirstTimeStatus = (state: RootState) => state.city.firstTime;
export default city.reducer;