"use client";

import { getCookie, setCookie } from "@/utils/functions/cookie";
import { persistor, store } from "@/shared/redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { keyGenerator } from "@/utils/functions/keyGenerator";
import React from "react";
interface IProvider {
  children: React.ReactNode;
}
const Provider = (props: IProvider) => {
  const {
    children
  } = props;
  if (typeof window !== "undefined" && !getCookie("deviceId")) setCookie("deviceId", keyGenerator(), {
    expires: 51840000
  });
  return <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-component="Provider" data-sentry-source-file="index.tsx">
      <PersistGate loading={null} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="index.tsx">
        {() => children}
      </PersistGate>
    </ReduxProvider>;
};
export default Provider;