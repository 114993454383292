"use client";

import { useABTest } from "@/hooks/useABTest";
import { useAuthModal } from "@/hooks/useAuthModal";
import { useCheckChangeCart } from "@/hooks/useCheckChangeCart";
import { useOpenModal } from "@/hooks/useOpenModal";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { clearNewUser, getAuthStatus } from "@/shared/redux/features/userSlice";
import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import ArrowTop from "@/shared/components/ArrowTop";
import CookiePopup from "@/shared/components/CookiePopup";
const AuthPopup = dynamic(() => import("@/shared/components/popups/auth-popup"), {
  ssr: false
});
const NewUserPopup = dynamic(() => import("@/shared/components/popups/NewUserPopup"), {
  ssr: false
});
const NavigationEvents = dynamic(() => import("@/shared/components/NavigationEvents"), {
  ssr: false
});
const Globals = () => {
  useABTest();
  useCheckChangeCart();
  const {
    showAuthModal,
    handleCloseAuthModal
  } = useAuthModal();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const userAuth = useAppSelector(getAuthStatus);
  const dispatch = useAppDispatch();
  const handleCloseNewUserPopup = () => {
    dispatch(clearNewUser());
    handleCloseModal();
  };
  useEffect(() => {
    if (userAuth.isAuth && userAuth.isNewUser && userAuth.welcomePromo) {
      handleOpenModal();
    }
  }, [userAuth]);
  return <>
      <NavigationEvents data-sentry-element="NavigationEvents" data-sentry-source-file="index.tsx" />
      {showAuthModal && <AuthPopup isOpen={showAuthModal} onClose={() => handleCloseAuthModal()} />}
      <NewUserPopup isOpen={showModal} onClose={() => handleCloseNewUserPopup()} coupon={userAuth.welcomePromo} data-sentry-element="NewUserPopup" data-sentry-source-file="index.tsx" />
      <ArrowTop data-sentry-element="ArrowTop" data-sentry-source-file="index.tsx" />
      <CookiePopup data-sentry-element="CookiePopup" data-sentry-source-file="index.tsx" />
    </>;
};
export default Globals;